import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { DevLoginForm } from '@cvent/nextjs/components/DevLoginForm';
import { GetServerSidePropsResult, Redirect } from 'next';
import { jsonFetchClient } from '@src/fetchClient';

const NORMANDY_LOGIN_PAGE = '/Subscribers/Login.aspx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPlannerSideUrl = (appContextInfo: any, relativeUrl = NORMANDY_LOGIN_PAGE, returnUrl = ''): string => {
  const queryParamString = `returnUrl=${encodeURIComponent(returnUrl)}`;

  const { normandyBaseUrl } = appContextInfo;
  return queryParamString ? `${normandyBaseUrl}${relativeUrl}?${queryParamString}` : `${normandyBaseUrl}${relativeUrl}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const redirectToNormandyLogin = (ctx: any): GetServerSidePropsResult<{ redirect: Redirect }> => {
  const { returnUrl } = ctx.query;
  return {
    redirect: {
      destination: getPlannerSideUrl(
        {
          normandyBaseUrl: process.env.normandyBaseUrl
        },
        NORMANDY_LOGIN_PAGE,
        returnUrl
      ),
      permanent: false
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps = (ctx: any): GetServerSidePropsResult<object> => {
  if (process.env.DEV_LOGIN === 'true') {
    return { props: {} };
  }
  return redirectToNormandyLogin(ctx);
};

const personas = {
  DevT2: {
    authorization: {
      metadata: {
        UserStub: 'a3fef701-9c47-4dba-b461-8b7d6bee6c09',
        accountMappingId: 'a3a1345d-cdac-4cc2-93db-5b532d7edb81',
        UserLoginName: 'bshen',
        LocaleId: 1033,
        AccountId: 801492674,
        environment: 'T2',
        locale: 'en-US'
      },
      roles: ['IS_PLANNER']
    }
  }
};

export default function LoginPage(): JSX.Element {
  const router = useRouter();
  const { query } = router;

  const login = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (tokenOptions: any): Promise<void> => {
      await jsonFetchClient.post('/api/login', { accessTokenOptions: tokenOptions });
      router.push((query.returnUrl as string) || '/');
    },
    [query.returnUrl, router]
  );

  return <DevLoginForm login={login} initialPersonas={personas} />;
}
